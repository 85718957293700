import React from "react"

const titleUnderline = ({ underline }) => (
  <span>
    {underline}
    <svg style={{ bottom: '5px' }}
      width="361" height="10" viewBox="0 0 361 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M67.1155 10C59.652 9.07169 5.00238 10.1081 1.03012 9.82831C-2.21286 9.45317 2.9697 2.84689 5.97993 1.87407C7.03506 1.52436 8.26087 1.47351 11.0694 1.63247C15.5227 1.93131 73.105 1.33997 73.105 1.33997C73.8653 1.54344 76.1462 0.856744 76.5652 1.10472C77.8841 1.68332 171.217 0.894891 179.177 0.8631C184.002 0.844025 180.185 1.23824 185.864 1.07928C197.967 0.761364 224.346 1.06657 233.516 0.704142C240.855 0.405302 242.438 0.392594 249.483 0.63421C256.682 0.850392 294.962 0.538832 299.338 0.074676C300.408 -0.0524901 301.231 -0.00797814 301.231 0.138263C301.231 0.170054 328.214 0.278142 328.338 0.392591C328.54 0.538832 329.362 0.608769 330.123 0.507036C331.535 0.354437 360.256 -0.198737 360.706 0.227269C362.118 1.44806 358.161 6.64915 354.825 7.91446C351.412 9.26878 332.668 7.74915 317.601 8.56302L247.45 8.64568L232.414 9.15434C230.692 8.71561 224.066 9.82196 220.063 9.03353C218.372 8.72197 206.424 8.67746 203.786 9.00809C201.49 9.26242 140.37 8.62023 134.101 9.50403C122.929 8.76647 77.6203 9.82197 67.1155 10Z" fill="#FFA700" />
    </svg>
  </span>
)

export default titleUnderline
