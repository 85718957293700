//logo de empresas clientes
import Rimac from "@components/pageCasosdeExito/image/imageCards/FotoRimac.png"
import Eriza from "@components/pageCasosdeExito/image/imageCards/Foto3eriza.png"
import Ibr from "@components/pageCasosdeExito/image/imageCards/FotoIbr.png"
import Gyt from "@components/pageCasosdeExito/image/imageCards/FotoGTAsesores.png"

const data = {
  products: [
    {
      image: Rimac,
      textbody:
        "Mira cómo ayudamos a su operación de telemarketing con Beex.",
      link: "/casos-de-exito/rimac/",
      tag: "Banca y Seguros | Telemarketing"
    },
    {
      image: Ibr,
      textbody:
        "Mira cómo aumentamos la contactabilidad de sus campañas con Beex.",
      link: "/casos-de-exito/ibr-peru/",
      tag: "Contact Center | Cobranzas"
    },
    {
      image: Eriza,
      textbody: "Descubre cómo potenciamos su operación de televentas con Beex.",
      link: "/casos-de-exito/3eriza/",
      tag: "Contact Center | Televentas "
    },
    {
      image: Gyt,
      textbody: "Descubre cómo ayudamos a su operación de cobranzas con Beex.",
      link: "/casos-de-exito/gt-asesores/",
      tag: "Contact Center | Cobranzas"
    },  
  ],
}

export default data
