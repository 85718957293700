/* eslint-disable no-undef */
import React from "react"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import dataOne from "@components/pageHome/data"
import data from "@components/pageCasosdeExito/data/dataPageCasoExito"
import CardsCasosExito from "@components/pageCasosdeExito/components/cardsPagesCasosExito"

const StructureMainPage = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div>
      <BannerCookies />
      <Header
        path="/casos-de-exito/"
        location={location}
        windowsWidth={windowsWidth}
      />
      <section className="container">
        <CardsCasosExito data={data.products} location={location} />
        <Footer data={dataOne.footer} location={location} />
        <FooterMobile data={dataOne.footer} location={location} />
      </section>
    </div>
  )
}

export default StructureMainPage
